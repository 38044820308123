import { useEffect, useRef, useState } from "react";
import { AviTracerApi } from "../../../avitracerApi";
import { useNavigate, useParams } from "react-router-dom";
import { Alert, Button, Col, Container, Row } from "react-bootstrap";
import { Document, Page, pdfjs } from 'react-pdf'
import { useWindowSize } from "usehooks-ts";
import SignatureCanvas from 'react-signature-canvas'
import { Player } from "@lottiefiles/react-lottie-player";
import LoadingComponent from "../../../components/LoadingComponent";
import { FlightCustomDocsType } from "../../../models/flight";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/3.6.172/pdf.worker.js`;

function FlightDocSignPage() {
  const navigate = useNavigate();

  const { width } = useWindowSize();

  const { flightId, docType } = useParams<{flightId: string, docType: FlightCustomDocsType}>();

  const [ pdfBuffer, setPdfBuffer ] = useState(null);
  const [ pdfDocProxy, setPdfDocProxy] = useState<pdfjs.PDFDocumentProxy>();
  const [ docSigned, setDocSigned ] = useState(false)
  const [ selectedAcceptanceStatus, setSelectedAcceptanceStatus ] = useState(false)
  
  const [errorStr, setErrorStr] = useState<string | null>(null);

  const signatureRef = useRef<SignatureCanvas>(null);
  const [inSignMode, setInSignMode] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadOk, setUploadOk] = useState(false);

  useEffect(() => {
    const getPdf = async () => {
      try {
      const pdfB = await AviTracerApi.getCustomPdfDoc(flightId!, docType!);
      setPdfBuffer(pdfB);
    } catch (e: any) {
      if (e === "SIGNED") {
        setErrorStr("Document has already been signed.")
        setDocSigned(true)
      } else {
        setErrorStr(e);
      }
    }
    };

    getPdf();
  }, [docType, flightId]);

  useEffect(() => {
    const getPdf = async () => {
      if (docSigned) {
        const pdfB = await AviTracerApi.getCustomPdfDoc(flightId!, docType!, true);
        setPdfBuffer(pdfB);
      }
    };
    getPdf();
  }, [docSigned, docType, flightId]);

  const submitSignature = async () => {
    if (signatureRef.current!.isEmpty()) {
      setErrorStr("Please sign in the above area.");
      return;
    }
    setErrorStr(null);
    
    setIsUploading(true);
    const signatureDataUrl = signatureRef.current!.getTrimmedCanvas().toDataURL();
    await AviTracerApi.signCustomPdfDoc(flightId!, docType!, signatureDataUrl, selectedAcceptanceStatus);
    setUploadOk(true)
    setIsUploading(false);
  };

  const getCurrentAcceptanceStatusFromPdfAndGoToSign = async () => {
    if (!pdfDocProxy) return;
    const savedPdfData = await pdfDocProxy.saveDocument();

    const savedPdf = await pdfjs.getDocument(savedPdfData).promise;
    const obj = await savedPdf.getFieldObjects();

    if (obj && obj["acceptance"]?.length > 1){
      setSelectedAcceptanceStatus((obj["acceptance"].at(-1) as any).value === "yes");
    }
    
    setInSignMode(true);
  }

  return (
    <>
      {!inSignMode && (
        <Container fluid={"xl"} style={{ paddingBottom: "120px" }}>
          <Row>
            <Col>
              {pdfBuffer ? (
                <Document
                  file={pdfBuffer}
                  loading={<LoadingComponent text="Loading document..." />}
                  onLoadSuccess={(d) => {
                    setPdfDocProxy(d);
                  }}
                >
                  <Page width={Math.min(width, 1280)} pageNumber={1} renderAnnotationLayer={true} renderForms={true} />
                </Document>
              ) : (
                <LoadingComponent text="Loading document..." />
              )}
            </Col>
          </Row>
          <Container className="fixed-bottom bg-light shadow" fluid>
            <Row className="justify-content-center py-3 ">
              {errorStr && (
                <>
                  <Col xs={10} lg={6}>
                    <Alert variant="danger">{errorStr}</Alert>
                  </Col>
                  <div className="w-100" />
                </>
              )}
              <Col xs={5} lg={3} xl={2}>
                <div className="d-grid">
                  <Button
                    variant="outline-secondary"
                    onClick={() => {
                      if (docSigned) {
                        navigate("../", { relative: "path" });
                      } else {
                        navigate("../edit", { relative: "path" });
                      }
                    }}
                  >
                    Edit document
                  </Button>
                </div>
              </Col>
              <Col xs={5} lg={3} xl={2}>
                <div className="d-grid">
                  <Button variant="dark" disabled={docSigned || errorStr !== null || !pdfDocProxy} onClick={getCurrentAcceptanceStatusFromPdfAndGoToSign}>
                    {docSigned ? "Already signed" : "Sign document"}
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
        </Container>
      )}
      {uploadOk && (
        <Row className="justify-content-center text-center pt-5">
          <Col>
            <Player
              autoplay
              loop
              src="/assets/brief/lottie/documentOk.json"
              speed={1}
              background="transparent"
              style={{ height: "200px", width: "200px" }}
            />
            <div className="fs-5 fw-bold pb-4">Document signed successfully.</div>
            {docType === "soloSupCertificate" ? (
              <Button
                onClick={() => {
                  navigate("../../riskAssessment", {relative: "path"});
                }}
                variant="dark"
              >
                Go to Risk Assessment
              </Button>
            ) : (
              <Button
                onClick={() => {
                  navigate("/");
                }}
                variant="dark"
              >
                Go to Flights
              </Button>
            )}
          </Col>
        </Row>
      )}
      {inSignMode && !uploadOk && (
        <>
          <div className="vw-100 vh-100 bg-white">
            <div className="bg-white w-100 h-100">
              <SignatureCanvas minWidth={2} maxWidth={5} ref={signatureRef} dotSize={3} canvasProps={{ className: "w-100 h-100" }} />
            </div>
          </div>
          <Container className="fixed-bottom bg-light shadow" fluid>
            <Row className="justify-content-center py-3 ">
              {errorStr && (
                <>
                  <Col xs={10} lg={6}>
                    <Alert variant="danger">{errorStr}</Alert>
                  </Col>
                  <div className="w-100" />
                </>
              )}
              <Col xs={5} lg={3} xl={2}>
                <div className="d-grid">
                  <Button
                    variant="outline-secondary"
                    disabled={isUploading}
                    onClick={() => {
                      setInSignMode(false);
                      setErrorStr(null);
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              </Col>
              <Col xs={5} lg={3} xl={2}>
                <div className="d-grid">
                  <Button variant="dark" disabled={isUploading} onClick={submitSignature}>
                    {!isUploading ? "Submit" : "Uploading"}
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
        </>
      )}
    </>
  );
}

export default FlightDocSignPage