import { EntityDetailsDisplayInfoFieldDateTime, getValitationRules } from "./EntityDetailsDisplayInfo";
import FlatPicker from 'react-flatpickr'
import { Col, Row } from "react-bootstrap";
import { Control, FieldValues, Path, PathValue, useController } from "react-hook-form";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons'

interface EntityDateTimePickerProps<Entity extends FieldValues> {
    displayInfoInput: EntityDetailsDisplayInfoFieldDateTime<Entity>;
    control: Control<Entity, any>
}

function EntityDateTimePicker<Entity extends FieldValues>({
  displayInfoInput,
  control,
}: EntityDateTimePickerProps<Entity>) {
  
  const {
    field: { value, onChange, onBlur, ref },
    fieldState: { invalid, error },
    formState: { isSubmitting, isValid },
  } = useController({
    name: displayInfoInput.key,
    control,
    defaultValue: displayInfoInput.defaultValue as PathValue<Entity, Path<Entity>> | undefined,
    rules: getValitationRules(displayInfoInput),
  });

  const utcOffset = (displayInfoInput.useUtc) ? moment(value).utcOffset() : 0;
  const entityValue = moment(value).toDate()
  const pickerTime = (value) ? moment(value).subtract(utcOffset, 'minutes').toDate() : undefined

  const isDisabled = (isValid && isSubmitting) || (displayInfoInput.disabled ?? false);

  const isInvalidClass = invalid ? " invalid" : "";

  return (
    <Row className="gy-3">
      <Col xs={12} md={6} className={displayInfoInput.showOnlyTimePicker ?? false ? "d-none" : ""}>
        <FlatPicker
          value={pickerTime}
          onOpen={(s,str,i) => {
            onBlur();
            if (displayInfoInput.suggestedValue && !value) {
              i.setDate(displayInfoInput.suggestedValue, false)
            }
          }}
          onClose={(s) => {
            const newUtcDate = moment(s[0]).add(utcOffset, "minutes").toDate();
            const old = entityValue;
            onChange(new Date(newUtcDate.getFullYear(), newUtcDate.getMonth(), newUtcDate.getDate(), old.getHours(), old.getMinutes()));
          }}
          options={{
            ...displayInfoInput.dateFlatpickrOptions,
            defaultDate: displayInfoInput.defaultValue,
            enableTime: false,
            static: true,
          }}
          className="form-control bg-white"
          render={({ value, ...props }, ref) => {
            return (
              <div className={"input-container rounded-lg".concat(isInvalidClass)} style={{ backgroundColor: "#F7F7F7" }}>
                <input
                  ref={ref}
                  disabled={isDisabled}
                  className="w-100 bg-transparent"
                  style={{ cursor: isDisabled ? "auto" : "pointer" }}
                  readOnly
                />
                <label className={value ? "filled" : ""}>{displayInfoInput.name}</label>
              </div>
            );
          }}
        />
      </Col>
      <Col xs={12} md={6} >
        <FlatPicker
          value={pickerTime}
          onOpen={(s,str,i) => {
            onBlur();
            if (displayInfoInput.suggestedValue && !value) {
              i.setDate(displayInfoInput.suggestedValue, false)
            }
          }}
          onClose={(s) => {
            const newUtcDate = moment(s[0]).add(utcOffset, "minutes").toDate();
            const old = entityValue;
            onChange(new Date(old.getFullYear(), old.getMonth(), old.getDate(), newUtcDate.getHours(), newUtcDate.getMinutes()));
          }}
          options={{
            ...displayInfoInput.timeFlatpickrOptions,
            defaultDate: displayInfoInput.defaultValue,
            enableTime: true,
            noCalendar: true,
            static: true,
            disableMobile: true
          }}
          className="form-control bg-white"
          render={({ value, ...props }, refFlatPickr) => {
            return (
              <div className={"input-container rounded-lg".concat(isInvalidClass)} style={{ backgroundColor: "#F7F7F7" }}>
                <input
                  ref={(r) => {
                    if (r !== null) {
                      refFlatPickr(r);
                      ref(r);
                    }
                  }}
                  disabled={isDisabled}
                  className="w-100 bg-transparent"
                  style={{ cursor: isDisabled ? "auto" : "pointer" }}
                  readOnly
                />
                <label className={value ? "filled" : ""}>{displayInfoInput.timeName}</label>
                {value && displayInfoInput.required === false && (
                  <FontAwesomeIcon
                    icon={faCircleXmark}
                    size="lg"
                    className="text-dark position-absolute top-50 end-0 translate-middle"
                    onClick={() => onChange(undefined)}
                  />
                )}
              </div>
            );
          }}
        />
      </Col>
      {error && <small className="text-danger">{error.message}</small>}
    </Row>
  );
}

export default EntityDateTimePicker;