import Flight, { FlightRiskAssessmentLevel } from "./flight"

export type Organization = {
    id: string
    name: string,
    role: "admin" | "pilot",
    riskAssessment: {
        enabled: boolean,
        shouldFillAllScoreFields: boolean,
        flightCategories: (Flight["flightCategory"])[]
        disableFplFlightCategories: (Flight["flightCategory"])[]
        levels: FlightRiskAssessmentLevel[]
        needSoloSupCertificate: boolean
    },
    isLatArmInWbPointUp: boolean,
    externalWalletPass?: {
        fieldDisplayName: string,
        formatDisplayError: string,
        formatRegex: string,
        passTypeId: string,
    },
    billing: {
        type: "prepaid" | "invoice"
        credits: number,
        canFileFpl: boolean,
        canCreateBriefingPack: boolean,
    },
    showOnlyAirfieldId?: string,
    airfieldSlots: {
        airfieldId: string,
        airfieldName: string,
    }[],
}

export function isAnonymousOrganization(org: Organization): boolean {
    return org.id.startsWith("anon_")
}

export function createTempAnonymousOrganizationForAirfield(uid: string, airfieldId: string): Organization {
   return {
        id: "anon_"+uid,
        name: "",
        role: "admin" as "admin" | "pilot",
        riskAssessment: {
            enabled: false,
            shouldFillAllScoreFields: false,
            flightCategories: [],
            disableFplFlightCategories: [],
            levels: [],
            needSoloSupCertificate: false
        },
        isLatArmInWbPointUp: false,
        airfieldSlots:[],
        showOnlyAirfieldId: airfieldId,
        billing: {
            type: "prepaid" as "prepaid" | "invoice",
            credits: 0,
            canFileFpl: false,
            canCreateBriefingPack: false
        }                
    }
}