import { useEffect, useMemo, useRef, useState } from "react";
import { AviTracerApi } from "../../../avitracerApi";
import { Alert, Button, Col, Container, Modal, Row } from "react-bootstrap";
import { Document, Page, pdfjs } from 'react-pdf'
import { useMatches, useNavigate, useParams } from "react-router-dom";
import { Player } from "@lottiefiles/react-lottie-player";

import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import '../../../css/ReactPdfAnnotation.css'
import { useWindowSize } from "usehooks-ts";
import LoadingComponent from "../../../components/LoadingComponent";
import { useAppSelector } from "../../../store/hooks";
import { selectedOrganization } from "../../../store/organizationsSlice";
import { FlightCustomDocsType, FlightRiskAssessmentLevel, getFlightRiskAssessmentLevelForScore } from "../../../models/flight";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/3.6.172/pdf.worker.js`;

function FlightDocFillPage() {
  const inEditMode = useMatches().at(-1)?.pathname.endsWith("/edit") || false;

  const navigate = useNavigate()
  const selectedOrg = useAppSelector(selectedOrganization);
  
  const { width } = useWindowSize()
  const { flightId, docType } = useParams<{flightId: string, docType: FlightCustomDocsType}>();

  const pageRef = useRef<HTMLDivElement>(null);
  const [pdfBuffer, setPdfBuffer] = useState(null);
  const [pdfDocProxy, setPdfDocProxy] = useState<pdfjs.PDFDocumentProxy>();

  const [uploadingDoc, setUploadingDoc] = useState(false);
  const [uploadOk, setUploadOk] = useState(false);

  const [scoreCalculatedOk, setScoreCalculatedOk] = useState(false);
  const [calculatedScore, setCalculatedScore] = useState(0);
  const [errorStr, setErrorStr] = useState<string | null>(null);

  const [showSignatureNeededModal, setShowSignatureNeededModal] = useState(false);
  const [signatureNeededByText, setSignatureNeededByText] = useState<string>("");

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isDeletingDocument, setIsDeletingDocument] = useState(false);

  const [docExists, setDocExists] = useState< "SIGNED" | "EXISTS" | false >(false)

  const allowEditOfDoc = useMemo(() => {
    if (docExists === "SIGNED") return false
    if (!docExists) return true
    return inEditMode
  }, [docExists, inEditMode])

  useEffect(() => {
    const getPdf = async () => {
      try {
        const pdfB = await AviTracerApi.getPdfDoc(flightId!, docType!);
        setPdfBuffer(pdfB);
      } catch (e: any) {
        if (e === "EXISTS") {
          setErrorStr("Document has already been created.")
          setDocExists("EXISTS");
        }else if (e === "SIGNED") {
          setErrorStr("Document has already been signed.")
          setDocExists("SIGNED");
        }else if (e === "NEED_SOLO_SUP_CERTIFICATE_SIGN") {
          navigate(`/fly/${flightId}/docs/soloSupCertificate/sign`);
        }else if (e === "NEED_SOLO_SUP_CERTIFICATE") {
          navigate(`/fly/${flightId}/docs/soloSupCertificate`);
        } else {
          setErrorStr(e);
        }
      }
    };

    getPdf();
  }, [docType, flightId, navigate, errorStr]);

  useEffect(() => {
    const getPdf = async () => {
      if (docExists) {
        const pdfB = await AviTracerApi.getCustomPdfDoc(flightId!, docType!, docExists === "SIGNED", inEditMode);
        setPdfBuffer(pdfB);
      }
    };
    getPdf();
  }, [docExists, docType, flightId, inEditMode]);

  const calculateScore = async (checkForError: boolean) => {
    if (!allowEditOfDoc){
      return
    }
    setScoreCalculatedOk(false)
    await new Promise((resolve) => setTimeout(resolve, 200));
    setErrorStr(null);

    if (!pdfDocProxy) return;

    const savedPdfData = await pdfDocProxy.saveDocument();

    const savedPdf = await pdfjs.getDocument(savedPdfData).promise;
    const obj = await savedPdf.getFieldObjects();

    if (!obj) return;
    if (!obj["totalScore"]) return;
    const totalScoreId = (obj["totalScore"][0] as any).id;

    const fieldsWithScores = Object.values(obj).filter((f) => f.length > 1 || ((f[0] as any).type === "checkbox" && (f[0] as any).value !== "Off") || ((f[0] as any).type === "combobox" ));

    var missingValue = false;
    const score = fieldsWithScores.reduce((acc, f) => {
      const vStr = (f[f.length - 1] as any).value as string;

      const isNum = !isNaN(parseInt(vStr));
      if (isNum) {
        acc += parseInt(vStr);
      } else {
        missingValue = true;
      }
      return acc;
    }, 0);

    setScoreCalculatedOk(!missingValue)
    setCalculatedScore(score);

    if (missingValue && checkForError) {
      setErrorStr("Please fill in all the scores.");
    }else {
      pdfDocProxy.annotationStorage.setValue(totalScoreId, { value: score.toString() });

      const totalScoreSection = Array.from(pageRef.current!.childNodes[2].childNodes).find(
        (n) => (n as any).dataset.annotationId === totalScoreId
      ) as HTMLDivElement;

      (totalScoreSection?.firstChild as HTMLInputElement).value = score.toString();
    }
  };

  const markAllFieldsAsChecked = async () => {
    if (!pdfDocProxy) return;

    const savedPdfData = await pdfDocProxy.saveDocument();

    const savedPdf = await pdfjs.getDocument(savedPdfData).promise;
    const obj = await savedPdf.getFieldObjects();
    if (!obj) return;

    Object.values(obj).filter((f) => (f[0] as any).type === "checkbox" && (f[0] as any).value !== "1" && !((f[0] as any).name as string).endsWith("-opt")).forEach((f) => {
      pdfDocProxy.annotationStorage.setValue((f[0] as any).id, { value: "1" });
      const section = Array.from(pageRef.current!.childNodes[2].childNodes).find(
        (n) => (n as any).dataset.annotationId === (f[0] as any).id
      ) as HTMLDivElement;
      (section?.firstChild as HTMLInputElement).checked = true;
    })
  }

  const uploadPdf = async () => {
    if (!allowEditOfDoc){
      return
    }

    setErrorStr(null);
    
    if (docType === "riskAssessment" && !scoreCalculatedOk && selectedOrg!.riskAssessment.shouldFillAllScoreFields) {
      setErrorStr("Please fill in all the fields.");
      return;
    }

    if (!pdfDocProxy) return;
    
    const savedPdfData = await pdfDocProxy.saveDocument();
    const fileBlob = new Blob([savedPdfData], { type: "application/pdf" });

    const savedPdf = await pdfjs.getDocument(savedPdfData).promise;
    const obj = (await savedPdf.getFieldObjects())!;

    var err: string | undefined = undefined;

    if ((obj["date"][0] as any).value === "") {
      err = "Please fill in the Date.";
    }
    if ((obj["timeUTC"]?.[0] as any)?.value === "") {
      err = "Please fill in the Time.";
    }
    if ((obj["registration"][0] as any).value === "") {
      err = "Please fill in the Aircraft registration.";
    }
    if ((obj["acType"][0] as any).value === "") {
      err = "Please fill in the Aircraft type.";
    }

    if ((obj["departure"]?.[0] as any)?.value === "") {
      err = "Please fill in the Departure.";
    }
    if ((obj["destination"]?.[0] as any)?.value === "") {
      err = "Please fill in the Destination.";
    }

    //Training
    if (obj["FIName"] && (obj["FIName"][0] as any).value === "") {
      err = "Please fill in the FI Name.";
    }
    if (obj["trainee"] && (obj["trainee"][0] as any).value === "") {
      err = "Please fill in the Trainee Name.";
    }

    //AOC
    if (obj["flightNumber"] && (obj["flightNumber"][0] as any).value === "") {
      err = "Please fill in the Flight Number.";
    }
    if (obj["pic"] && (obj["pic"][0] as any).value === "") {
      err = "Please fill in the PIC.";
      
    }

    if (err) {
      setErrorStr(err);
      return
    }

    var riskAssessmentLevel: FlightRiskAssessmentLevel | undefined

    if (docType === "soloSupCertificate") {
      const allOffCheckboxes  = Object.values(obj).filter((f) => (f[0] as any).type === "checkbox" && (f[0] as any).value !== "1" && !((f[0] as any).name as string).endsWith("-opt"));
      if (allOffCheckboxes.length > 0){
        setErrorStr("There are unchecked required actions.");
        return;
      }

    }else if (docType === "riskAssessment") {
      riskAssessmentLevel = getFlightRiskAssessmentLevelForScore(calculatedScore, selectedOrg!.riskAssessment.levels);
      if(riskAssessmentLevel.requestSignFrom && !showSignatureNeededModal){
        setSignatureNeededByText(riskAssessmentLevel.requestSignFrom.name)
        setShowSignatureNeededModal(true);
        return
      }  
    }

    setUploadingDoc(true);
    setShowSignatureNeededModal(false);

    try{
      if (inEditMode){
        await AviTracerApi.editCustomPdfDoc(flightId!, docType!, fileBlob);
      }else{
        await AviTracerApi.createCustomPdfDoc(flightId!, docType!, fileBlob);
      }
      
      if (riskAssessmentLevel && riskAssessmentLevel.requestSignFrom){
        await AviTracerApi.requestCustomPdfDocSignature(flightId!, docType!)
      }
      setUploadOk(true);
    }catch(e: any){
      setErrorStr(e);
    }
    
    setUploadingDoc(false);
  };

  useEffect(() => {
    if (uploadOk && inEditMode) {
      navigate("../sign", { relative: "path" });
    }
  }, [uploadOk, navigate, inEditMode]);

  const requestDeletePdf = async () => {
    setIsDeletingDocument(true);
    await AviTracerApi.deleteCustomPdfDoc(flightId!, docType!)
    setIsDeletingDocument(false);
    window.location.reload()
  }

  return (
    <>
      <Container fluid={"xl"} style={{ paddingBottom: "120px" }}>
        {uploadOk && (
          <Row className="justify-content-center text-center pt-5">
            <Col>
              <Player
                autoplay
                loop
                src="/assets/brief/lottie/documentOk.json"
                speed={1}
                background="transparent"
                style={{ height: "200px", width: "200px" }}
              />
              <div className="fs-5 fw-bold pb-4">Document uploaded successfully.</div>
              {docType === "soloSupCertificate" ? (
                <Button
                  onClick={() => {
                    navigate("./sign", { relative: "path" });
                  }}
                  variant="dark"
                >
                  Sign Document
                </Button>
              ) : (
                <Button
                  onClick={() => {
                    navigate("/");
                  }}
                  variant="dark"
                >
                  Go to Flights
                </Button>
              )}
            </Col>
          </Row>
        )}
        <Row className={"justify-content-center ".concat(uploadingDoc || uploadOk ? "d-none" : "")}>
          <Col>
            {pdfBuffer ? (
              <Document
                file={pdfBuffer}
                loading={<LoadingComponent text="Loading document..." />}
                onLoadSuccess={(d) => {
                  setPdfDocProxy(d);
                }}
              >
                <Page
                  width={Math.min(width, 1280)}
                  pageNumber={1}
                  inputRef={pageRef}
                  renderAnnotationLayer={allowEditOfDoc}
                  renderForms={allowEditOfDoc}
                  onMouseDown={(e) => {
                    if (!allowEditOfDoc) return;
                    if (e.target?.checked) {
                      setTimeout(() => {
                        e.target.checked = false;
                        pdfDocProxy?.annotationStorage.remove(e.target.dataset.elementId)
                      }, 100);
                    }
                    setTimeout(() => {
                      calculateScore(false);
                    }, 100);
                  }}
                />
              </Document>
            ) : (
              <LoadingComponent text="Loading document..." />
            )}
          </Col>
        </Row>
        {!uploadOk && (
          <Container className="fixed-bottom bg-light shadow" fluid>
            <Row className="justify-content-center py-3 ">
              {errorStr && (
                <>
                  <Col xs={10} lg={6}>
                    <Alert variant="danger">{errorStr}</Alert>
                  </Col>
                  <div className="w-100" />
                </>
              )}
              {docType === "soloSupCertificate" && (
                <Col xs={5} lg={3} xl={2}>
                  <div className="d-grid">
                    <Button variant="outline-dark" disabled={!pdfDocProxy || uploadingDoc} onClick={markAllFieldsAsChecked}>
                      Check all
                    </Button>
                  </div>
                </Col>
              )}
              <Col xs={5} lg={3} xl={2}>
                <div className="d-grid">
                  {!docExists ? (
                    <Button variant="dark" disabled={ !pdfDocProxy || uploadingDoc} onClick={uploadPdf}>
                      Submit
                    </Button>
                  ) : (
                    <>
                      {inEditMode ? (
                        <Button variant="dark" disabled={ !pdfDocProxy || uploadingDoc || docExists === "SIGNED"} onClick={uploadPdf}>
                          Resubmit document
                        </Button>
                      ) : (
                        <Button variant="dark" disabled={ !pdfDocProxy || uploadingDoc} onClick={() => setShowDeleteModal(true)}>
                          Delete document
                        </Button>
                      )}
                    </>
                  )}
                </div>
              </Col>
            </Row>
          </Container>
        )}
      </Container>
      {uploadingDoc && <LoadingComponent text="Uploading..." />}

      <Modal show={showSignatureNeededModal}>
        <Modal.Header>
          <Modal.Title>Signature required</Modal.Title>
        </Modal.Header>
        <Modal.Body>An automated email will be sent to {signatureNeededByText} to sign this Flight.</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowSignatureNeededModal(false)}>
            Go Back
          </Button>
          <Button variant="dark" onClick={uploadPdf}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showDeleteModal}>
        <Modal.Header>
          <Modal.Title>Delete document?</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete the current document? Please note that this action cannot be undone.</Modal.Body>
        <Modal.Footer>
          {!isDeletingDocument && (
            <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
              Go Back
            </Button>
          )}
          <Button disabled={isDeletingDocument} variant="danger" onClick={requestDeletePdf}>
            {!isDeletingDocument ? "Delete" : "Deleting..."}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default FlightDocFillPage;