import { ControllerFieldState, Noop, RefCallBack } from 'react-hook-form';
import './inputBoxStyle.css';

type InputBoxProps = {
  value: string;
  isDisabled: boolean,
  name: string
  uppercase?: boolean
  field?: {
    onChange: (...event: any[]) => void
    onBlur: Noop;
    ref: RefCallBack
  }
  fieldState?: ControllerFieldState
  invalidError?: string
  inputRef?: RefCallBack
  type?: "text" | "number"
  labelStyle?: React.CSSProperties
} & DynamicInputType

function InputBox({ value, onChange, isDisabled, name, type, uppercase, field, fieldState, invalidError, inputRef, labelStyle }: InputBoxProps) {
  const {error, invalid} = fieldState ?? (invalidError ? {error: {message: invalidError}, invalid: true} : {error: undefined, invalid: false})


  var displayValue = value ?? ""
  displayValue = (type === "number" && displayValue.length > 0) ? Number(displayValue).toString() : displayValue

  return (
    <>
      <div className={(invalid ? "invalid " : "") + "input-container rounded-lg "}>
        <input
          onBlur={(e) => {
            field?.onBlur();
            if (type !== "number") {
              // Trim value when user finishes typing
              const trimmedValue = e.target.value.trim();
              if (trimmedValue !== e.target.value) {
                e.target.value = trimmedValue;
                field?.onChange(trimmedValue);
                onChange(trimmedValue);
              }
            }
          }}
          ref={field?.ref || inputRef}
          inputMode={type === "number" ? "numeric" : undefined}
          type={type ?? "text"}
          disabled={isDisabled}
          className="bg-transparent"
          value={displayValue}
          onWheel={(e) => {e.currentTarget.blur()}}
          onInput={(e) => { 
            if (uppercase) {
              const start = e.currentTarget.selectionStart
              const end = e.currentTarget.selectionEnd
              e.currentTarget.value = ("" + e.currentTarget.value).toUpperCase();
              e.currentTarget.setSelectionRange(start, end)
            }
          }}
          onChange={(e) => {
            
            if (type === "number") {
              if (e.target.value === "") {
                field?.onChange(null);
              } else {
                field?.onChange(Number(e.target.value));
              }
              onChange(Number(e.target.value));
            } else {
              onChange(e.target.value);
              field?.onChange(e.target.value);
            }
          }}
        />
        <label  style={labelStyle} className={(displayValue.toString().length > 0) ? "filled" : ""}>{name}</label>
      </div>
      {error && <small className="text-danger">{error.message}</small>}
    </>
  );
}

export default InputBox;

type InputBoxTypeValuesMap = {
  text: string,
  number: number
}

type InputBoxType = keyof InputBoxTypeValuesMap

type Value<Type extends InputBoxType> = InputBoxTypeValuesMap[Type]

type Test<Type extends InputBoxType> = {
  type: Type
  onChange: (newValue: Value<Type>) => void
}

type DefaultType = {
  type?: undefined
  onChange: (newValue: string) => void
}

type DynamicInputType =
  | Test<'number'>
  | Test<'text'>
  | DefaultType